<template>
  <div class="register">
    <div class="from-box">
      <h2>用户注册</h2>
      <el-form
        :model="registerFrom"
        ref="registerRuleForm"
        label-width="100px"
        class="demo-ruleForm"
        :rules="rules"
      >
        <el-form-item label="真实姓名" prop="realName">
          <el-input
            v-model="registerFrom.realName"
            placeholder="请输入真实姓名"
          ></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="pass">
          <el-input
            type="password"
            v-model="registerFrom.pass"
            autocomplete="off"
            placeholder="请输入6-10字符新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            type="password"
            v-model="registerFrom.checkPass"
            autocomplete="off"
            placeholder="请再次输入新密码"
          ></el-input>
        </el-form-item>

        <el-form-item label="身份证号" prop="IDNumber">
          <el-input
            v-model="registerFrom.IDNumber"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="cellPhoneNumber">
          <el-input
            v-model="registerFrom.cellPhoneNumber"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('registerRuleForm', registerFrom)"
            >注册</el-button
          >
        </el-form-item>
      </el-form>
      <div class="registerTips">
        已有账号？立即<a @click="registerHand">登录</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.registerFrom.checkPass !== "") {
          this.$refs.registerRuleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.registerFrom.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    var RealName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入真实姓名"));
      } else {
        const reg = /^(?:[\u4e00-\u9fa5·]{2,16})$/;
        const card = reg.test(value);
        if (!card) {
          callback(new Error("请填写正确的姓名格式"));
        } else {
          callback();
        }
      }
    };
    //身份证号
    var isCardId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入身份证号"));
      } else {
        const reg =
          /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/;
        const card = reg.test(value);
        if (!card) {
          //判断座机为12位
          callback(new Error("请填写正确身份证号"));
        } else {
          callback();
        }
      }
    };
    //手机号正则校验
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error("请输入正确的手机号"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入手机号"));
        }
      }
    };
    return {
      registerFrom: {
        realName: "",
        checkPass: "",
        pass: "",
        IDNumber: "",
        cellPhoneNumber: "",
      },
      newData: [],
      rules: {
        realName: [{ validator: RealName, trigger: "blur", required: true }],
        pass: [
          { validator: validatePass, trigger: "blur" },
          { required: true, message: "请填写6-10字符密码", trigger: "blur" , pattern: /^.{6,10}$/,},
        ],
        checkPass: [
          { validator: validatePass2, trigger: "blur" },
          { required: true, message: "请再次输入密码", trigger: "blur" },
        ],
        IDNumber: [{ validator: isCardId, trigger: "blur", required: true }],
        cellPhoneNumber: [
          { validator: isMobileNumber, trigger: "blur", required: true },
        ],
      },
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    localStorage.getItem("data")
      ? ""
      : localStorage.setItem("data", JSON.stringify(this.newData));
    onpopstate = () => {
      //后退按钮触发事件
      let type = sessionStorage.getItem("type");
      sessionStorage.setItem("type", type === "1" ? "0" : "1");
    };
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    submitForm(registerRuleForm, data) {
      this.$refs[registerRuleForm].validate((valid) => {
        if (valid) {
          let newList = {
            IDNumber: data.IDNumber,
            cellPhoneNumber: data.cellPhoneNumber,
            checkPass: data.checkPass,
            pass: data.pass,
            realName: data.realName,
          };
          const StoreData = JSON.parse(localStorage.getItem("data"));
          let ifList = StoreData.filter(
            (item) => item.cellPhoneNumber === data.cellPhoneNumber
          );
          if (ifList.length > 0) {
            this.$message.error("该手机号已存在!");
            this.registerFrom.cellPhoneNumber = "";
          } else {
            this.newData.push(newList);

            const newData = JSON.parse(localStorage.getItem("data"));
            newData.push(newList);
            localStorage.getItem("data")
              ? localStorage.setItem("data", JSON.stringify(newData))
              : localStorage.setItem("data", JSON.stringify(this.newData));
            this.$router.push({ path: "/login" });
            this.$message({
              message: "注册成功",
              type: "success",
            });
          }
          // this.registerFrom.realName = "";
          // this.registerFrom.checkPass = "";
          // this.registerFrom.pass = "";
          // this.registerFrom.IDNumber = "";
        } else {
          return false;
        }
      });
    },
    registerHand() {
      this.$router.push("/login");
    },
  },
};
</script>


<style lang="scss" scoped>
.register {
  max-width: 1920px;
  min-height: 904px;
  background: #f5f7fa;
  display: flex;
  justify-content: center;
  padding-top: 31px;
}
.from-box {
  width: 489px;
  height: 700px;
  background: #ffffff;
}
h2 {
  margin-bottom: 36px;
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #302e37;
  line-height: 25px;
  margin-top: 45px;
}
::v-deep.demo-ruleForm {
  width: 400px;
}
::v-deep .el-input {
  width: 350px;
  height: 50px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(48, 46, 55, 0.1);
}
::v-deep .el-input__inner {
  width: 350px;
  height: 50px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(48, 46, 55, 0.1);
}
.el-button {
  width: 350px;
  height: 50px;
  background: #ff7d3e;
  border-radius: 2px;
  border: 1px solid #ff7d3e;
}
.registerTips {
  text-align: right;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #302e37;
  line-height: 20px;
  a {
    color: #ff7d3e;
    text-decoration: none;
    margin-right: 40px;
  }
}
</style>